import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import ProductListPage from '~/components/Product/ListPage'

const Shop = ({ data }) => {
  if (!data || !data.catalog) {
    return null
  }
  const { page, catalog } = data
  const filteredCatalogCollections = catalog?.collections?.filter(
    collection => !collection.hidden,
  )
  catalog.collections = filteredCatalogCollections
  return (
    <Layout>
      <ProductListPage page={page} catalog={catalog} />
    </Layout>
  )
}

export default Shop

export const query = graphql`
  query ShopPage($locale: String) {
    catalog: contentfulCatalog(
      slug: { eq: "catalog" }
      node_locale: { eq: $locale }
    ) {
      collections {
        ...CollectionDetailsFragment
      }
    }
    page: contentfulPage(
      slug: { eq: "shop-all" }
      node_locale: { eq: $locale }
    ) {
      name
      ...ContentHeroFragment
    }
  }
`
